import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./Company.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { Nav } from "../Discs/Nav";
import { AppContext } from "../App";

const Body = (props: {}) => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const p1Ref = useRef<HTMLParagraphElement>(null);
  const p2Ref = useRef<HTMLParagraphElement>(null);
  const box3Ref = useRef<HTMLParagraphElement>(null);
  let fadeInElements = document.querySelectorAll<HTMLElement>(".fade-in");
  let tiao1Elements = document.querySelectorAll<HTMLElement>(".tiao1");
  let tiao2Elements = document.querySelectorAll<HTMLElement>(".tiao2");
  const dang = useRef<HTMLParagraphElement>(null);
  const boxRef = [
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
    [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)],
  ];
  const { context, setContext } = useContext(AppContext);
  useEffect(() => {
    console.log(context.currentPage);
    if (context.currentPage >= 2.99999982 && context.currentPage < 5.1) {

      if (dang.current && context.currentPage < 5 && context.currentPage >= 3) {
        dang.current.style.display = "block";
      }
      // console.log(context.currentPage);
      if (bannerRef.current) {
        var leftString = (92-(context.currentPage-3)*92).toString() + "vw";
        if(context.currentPage >4.99999999 )
        {
          leftString = "-92vw";
        }
        bannerRef.current.style.left = leftString;
      }
    } else {
      if (dang.current ) {
        dang.current.style.display = "none";
      }
    }
    // console.log(context.currentPage);
    if (context.currentPage >= 6.9 && context.currentPage < 7.1) {
      tiao1Elements.forEach(function (element) {
        element.style.backgroundSize = "100% 100%";
      });
      tiao2Elements.forEach(function (element) {
        element.style.backgroundPosition = "0 100%";
        element.style.backgroundSize = "100% 100%";
      });
      fadeInElements.forEach(function (element) {
        element.style.opacity = "1";
        element.style.transition = "opacity 2s";
        element.style.transitionDelay = "1s";
      });
    } else {
      tiao1Elements.forEach(function (element) {
        element.style.backgroundSize = "100% 0";
      });
      tiao2Elements.forEach(function (element) {
        element.style.backgroundSize = "100% 0";
      });
      fadeInElements.forEach(function (element) {
        element.style.opacity = "0";
      });
    }
    if (context.currentPage >= 7.9 && context.currentPage < 8.1) {
      // console.log(context.currentPage);
      if (box3Ref.current) {
        box3Ref.current.style.clipPath =
          "polygon(-20% 0, 100% 0, 100% 100%, -20% 100%)";
      }
    } else {
      if (box3Ref.current) {
        box3Ref.current.style.clipPath =
          "polygon(-30% 0, -30% 0, -30% 100%, -30% 100%)";
      }
    }
  }, [context.currentPage]);

  useEffect(() => {
  

    var isBoxAvailable = true;
    boxRef.forEach((pair) => {
      if (pair[0].current && pair[1].current) {
      } else {
        isBoxAvailable = false;
      }
    });
    if (isBoxAvailable && p1Ref.current && p2Ref.current) {
      var speed = 0;

      const box: Array<Array<CSSStyleDeclaration>> = [];
      const p1 = p1Ref.current!.style;
      const p2 = p2Ref.current!.style;
      boxRef.forEach((pair) => {
        box.push([pair[0].current!.style, pair[1].current!.style]);
      });

      const setTop = (boxGroup: Array<CSSStyleDeclaration>, top: number) => {
        boxGroup.forEach((boxItem) => {
          boxItem.top = top.toString() + "vh";
        });
      };

      const getTop = (boxGroup: Array<CSSStyleDeclaration>) => {
        var topString = boxGroup[0].top;
        topString = topString.substring(0, topString.indexOf("vh"));
        return parseFloat(topString);
      };

      const toZero = (): boolean => {
        var currentTop = getTop(box[0]);
        const distance = Math.abs(currentTop);
        speed = Math.min(speed + 0.1, 2, distance * 0.2);
        setTop(box[0], -distance + speed);
        setTop(box[1], distance - speed);
        setTop(box[2], -distance + speed);
        setTop(box[3], -distance + speed);
        setTop(box[4], distance - speed);
        setTop(box[5], -distance + speed);
        return distance < 1;
      };

      const setHide = () => {
        setTop(box[0], -100);
        setTop(box[1], 100);
        setTop(box[2], -100);
        setTop(box[3], -100);
        setTop(box[4], 100);
        setTop(box[5], -100);
        p1.opacity = "0";
        p2.opacity = "0";
      };

      const setOpacity = () => {
        var currentOpacity = parseFloat(p1.opacity);
        currentOpacity = 0.95 * currentOpacity + 0.05;
        p2.opacity = p1.opacity = currentOpacity.toString();
      };

      const scroll = () => {
        setContext((newContext) => {
          const page = newContext.currentPage;
          if (page > 5.1 && page < 6.9) {
            if (toZero()) {
              setOpacity();
            }
          } else {
            setHide();
          }
        });
        requestAnimationFrame(scroll);
      };
      scroll();
    }
  }, []);
  return (
    <div className={styles.body}>
      <div ref={dang} className={styles.dang}></div>
      <div
        className={styles.page}
        style={{ position: "relative", flexDirection: "column" }}
      >
        <p className={styles.p_1} style={{ left: "0vw", top: "5vw" }}>
          公司概况
        </p>
        <div className={styles.banner} ref={bannerRef}>
          <div
            className={styles.banner1}
          >
            <div className={styles.banner2_1}>
              <div className={styles.box1_1}>
                <p className={styles.p_2}>
                  立足浙江、瞄准全球的未来数智人独角兽企业
                </p>
              </div>
              <div className={styles.box2_1}>
                <p className={styles.p_3}>
                杭州像素跃迁科技有限公司致力于成为一家立足杭州,辐射长三角,服务全球的未来独⻆兽企业,专注于AGI(通用人工智能)技术及其数智人相关领域的研发。我们的核心业务围绕着一站式数智人定制服务、技能热更新烘焙引擎以及EmotionChat模型等展开,致力于为企业构建高模块化的、低代码的数智人,提供精准识别的情绪数据库以及对应的情绪识别模型。
                </p>
              </div>
            </div>
          </div>
          <div
            className={styles.banner1}
          >
            <div className={styles.banner2_2}>
              <div className={styles.box1_2}>
                <p className={styles.p_2}>
                  一路峥嵘，融合工程设计与人工智能的领导者
                </p>
              </div>
              <div className={styles.box2_2}>
                <p className={styles.p_3}>
                杭州像素跃迁科技有限公司的创始人陈小雨博士是专注于人工通用智能 (AGI) ​技术研发的科技领导者。2022年陈小雨博士在浙江大学计算机科学与技术学院获得工学博士学位,专业知识涵盖了自然语言处理、语音识别、计算机视觉、机器学习以及人工智能等多个领域,同时修读浙江大学竺可桢荣誉学院创新创业强化班,在解决问题时有着多角度的视角、深厚的技术底蕴和独特的商业洞察。
                </p>
              </div>
            </div>
          </div>
          <div
            className={styles.banner1}
          >
            <div className={styles.banner2_3}>
              <div className={styles.box1_3}>
                <p className={styles.p_2}>
                  技术与情感相融合，
                  <br />
                  打造有情感智慧的AI开发团队
                </p>
              </div>
              <div className={styles.box2_3}>
                <p className={styles.p_3}
                style={
                  {
                    marginTop: "0.5vw",
                  }
                }>
               我司汇聚了浙江大学、中国美术学院的优秀人才,涵盖自然语言处理、语音识别、计算机视觉、机器学习与人工智能等多领域的专家。我司的初心使命是将技术与情绪交相辉映,构建出人性化的AI,让AGI数智人在与用户的交互中提供积极正向的情绪反馈,推动其思维和精神的丰富提升。同时我们公司立足于浙大校园,坚持人才为本的理念,构建了AlphaLab实验室作为人才孵化基地。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles.banner1} style={{ top: "2vw" }}>
          <p
            className={styles.p_1}
            style={{ top: "0.2vw", opacity: "1" }}
            ref={p1Ref}
          >
            荣誉资历
          </p>
          <p className={styles.p_4} style={{ opacity: "1" }} ref={p2Ref}>
            公司荣获IF设计奖两项、红点设计奖两项、DIA设计奖三项、IDA设计奖两项、IDEA设计奖两项、国家专利24项等众多国内外知名大奖
          </p>
          <div className={styles.table_1}>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[0][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[1][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[2][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[3][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[4][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[5][0]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[0][1]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[1][1]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[2][1]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[3][1]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[4][1]}
            ></div>
            <div
              className={styles.imgs}
              style={{ position: "relative", top: "0vh" }}
              ref={boxRef[5][1]}
            ></div>
          </div>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles.banner1}>
          <p
            className={`${styles.p_1} fade-in`}
            style={{ top: "0.7vw", opacity: "0" }}
          >
            部门介绍
          </p>
          <div className={styles.banner3}>
            <div className={styles.document}>
              <p className={`${styles.p_5} fade-in`}>智能技术部</p>
              <p className={`${styles.p_6} fade-in`}>
              研发和创新公司核心产品的AGI技术,直接影响产品的质量和创新性,对公司的ToB业务以及后期的ToC业务具有决定性的作用。
              </p>
            </div>
            <div className={`${styles.tiao} tiao1`}></div>
            <div className={styles.document}>
              <p className={`${styles.p_5} fade-in`}>项目探索部</p>
              <p className={`${styles.p_6} fade-in`}>
              探寻和发展新的项目和业务机会,推动公司业务的拓展。直接影响公司业务的拓展,有助于实现从ToB到ToC的业务转型。
              </p>
            </div>
            <div className={`${styles.tiao} tiao2`}></div>
            <div className={styles.document}>
              <p className={`${styles.p_5} fade-in`}>创新设计部</p>
              <p className={`${styles.p_6} fade-in`}>
              处理产品的创意和设计,负责优化用户体验。确保产品具有高度的用户友好性和使用满意度。
              </p>
            </div>
            <div className={`${styles.tiao} tiao1`}></div>
            <div className={styles.document}>
              <p className={`${styles.p_5} fade-in`}>组织管理部</p>
              <p className={`${styles.p_6} fade-in`}>
              管理公司的日常运营,负责人力资源、财务管理及行政事务。为公司其他部⻔提供必要的支持,保证公司运营的高效和顺畅。
              </p>
            </div>
            <div className={`${styles.tiao} tiao2`}></div>
            <div className={styles.document}>
              <p className={`${styles.p_5} fade-in`}>见习培育部</p>
              <p className={`${styles.p_6} fade-in`}>
              负责新人培养和员工发展计划,以保障公司人才的持续供给。支持公司的⻓远发展和创新能力。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles.banner4}>
          <div className={styles.team}></div>
          <div className={styles.box3} ref={box3Ref}>
            <p className={styles.p_1}>团队介绍</p>
            <div className={styles.tag}>
              <div className={styles.box4}>
                <p className={styles.p_7}>学术背景丰富</p>
              </div>
              <div className={styles.box4}>
                <p className={styles.p_7}>构成领域多元</p>
              </div>
              <div className={styles.box4} style={{ marginRight: "0" }}>
                <p className={styles.p_7}>专业技能广泛</p>
              </div>
            </div>
            <div className={styles.box2_4}>
              <p className={styles.p_3}>
                团队成员40+人，拥有<span>浙江⼤学</span>、
                <span>卡内基梅隆⼤学</span>、<span>新加坡国立大学</span>以及
                <span>中国美术学院</span>
                等世界顶尖学府的丰富学术背景。公司的成员构成极其多元,既包括来自计算机科学、自动化控制、电子科学与技术领域的专业人才,也有具备工商管理、行政管理等背景的博士、硕士以及本科生,且
                <span>研发团队</span>在公司总⼈数中占比超过<span>70%</span>。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CompanyPage = (props: {}) => {
  return (
    <div>
      {/* <Nav page={3} /> */}
      <Body />
    </div>
  );
};

// export default Home;
