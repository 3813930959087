import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./Product.module.scss";
import { useNavigate } from "react-router-dom";
import { Nav } from "../Discs/Nav";
import { AppContext } from "../App";
var state = 0;

class AnimationCircle {
  circle: CSSStyleDeclaration;
  speedX: number;
  speedY: number;

  constructor(circle: CSSStyleDeclaration) {
    this.circle = circle;
    this.speedX = 0;
    this.speedY = 0;
  }

  get_size(): number {
    var widthString = this.circle.width;
    widthString = widthString.substring(0, widthString.indexOf("vw"));
    const width = parseFloat(widthString);
    return width;
  }

  opacity(opacity: number, ratio: number) {
    const opacityString = this.circle.opacity;
    var currentOpacity = parseFloat(opacityString);
    currentOpacity = currentOpacity * (1 - ratio) + opacity * ratio;
    this.circle.opacity = currentOpacity.toString();
  }

  resize(size: number, speed_correction: number = 0): boolean {
    var currentSize = this.get_size();
    currentSize =
      currentSize * (0.98 - speed_correction) +
      size * (0.02 + speed_correction);
    this.set_size(currentSize);
    return this.get_size() == size;
  }

  set_size(size: number) {
    const sizeString = size.toString() + "vw";
    this.circle.width = sizeString;
    this.circle.height = sizeString;
  }

  get_x(): number {
    var leftString = this.circle.left;
    leftString = leftString.substring(0, leftString.indexOf("vw"));
    const left = parseFloat(leftString);
    return left;
  }

  get_y(): number {
    var topString = this.circle.top;
    topString = topString.substring(0, topString.indexOf("vw"));
    const top = parseFloat(topString);
    return top;
  }

  set_x(x: number) {
    const leftString = x.toString() + "vw";
    this.circle.left = leftString;
  }

  set_y(y: number) {
    const topString = y.toString() + "vw";
    this.circle.top = topString;
  }

  norm(x: number, y: number) {
    return Math.sqrt(x * x + y * y);
  }

  respace(position: { x: number; y: number }, speed_correction: number = 0) {
    var currentPosition = this.position();
    currentPosition.x =
      (0.98 - speed_correction) * currentPosition.x +
      (0.02 + speed_correction) * position.x;
    currentPosition.y =
      (0.98 - speed_correction) * currentPosition.y +
      (0.02 + speed_correction) * position.y;
    this.set_x(currentPosition.x);
    this.set_y(currentPosition.y);
  }

  move(position: { x: number; y: number }): boolean {
    var currentPosition = this.position();
    const deltaX = position.x - currentPosition.x;
    const deltaY = position.y - currentPosition.y;
    const deltaDistance = this.norm(deltaX, deltaY);
    if (deltaDistance == 0) {
      return true;
    }
    const maxSpeed = Math.min(deltaDistance * 0.1, 1);
    const targetSpeedX = deltaX / deltaDistance;
    const targetSpeedY = deltaY / deltaDistance;
    const deltaSpeedX = targetSpeedX - this.speedX;
    const deltaSpeedY = targetSpeedY - this.speedY;
    const deltaSpeed = this.norm(deltaSpeedX, deltaSpeedY);
    if (deltaSpeed < 0.1) {
      this.speedX = targetSpeedX;
      this.speedY = targetSpeedY;
    } else {
      const accelerationX = (deltaSpeedX / deltaSpeed) * 0.01;
      const accelerationY = (deltaSpeedY / deltaSpeed) * 0.01;
      this.speedX += accelerationX;
      this.speedY += accelerationY;
    }
    const currentSpeed = this.norm(this.speedX, this.speedY);
    if (currentSpeed > maxSpeed) {
      this.speedX *= maxSpeed / currentSpeed;
      this.speedY *= maxSpeed / currentSpeed;
    }

    currentPosition.x += this.speedX;
    currentPosition.y += this.speedY;
    this.set_x(currentPosition.x);
    this.set_y(currentPosition.y);
    return false;
  }

  position(): { x: number; y: number } {
    const x = this.get_x();
    const y = this.get_y();
    return { x: x, y: y };
  }
}
const Body = (props: {}) => {
  const circle1Ref = useRef<HTMLDivElement>(null);
  const circle2Ref = useRef<HTMLDivElement>(null);
  const circle3Ref = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const { context, setContext } = useContext(AppContext);

  useEffect(() => {
    if (
      circle1Ref.current &&
      circle2Ref.current &&
      circle3Ref.current &&
      pageRef.current
    ) {
      const circle1 = new AnimationCircle(circle1Ref.current.style);
      const circle2 = new AnimationCircle(circle2Ref.current.style);
      const circle3 = new AnimationCircle(circle3Ref.current.style);
      var time11 = 0;
      var time10 = 0;
      var opacity_page_0 = "1";
      var opacity_page_1 = "0";
      var pElement = document.querySelectorAll<HTMLElement>(".pe");
      var line = document.querySelectorAll<HTMLElement>(".line");
      const move = () => {
        if (pElement.length == 0) {
          pElement = document.querySelectorAll<HTMLElement>(".pe");
        }
        if (line.length == 0) {
          line = document.querySelectorAll<HTMLElement>(".line");
        }
        setContext((newContext) => {
          if (newContext.page >= 12) {
            time11 = time11 + 1;
            time10 = 0;
            opacity_page_0 = (parseFloat(opacity_page_0) * 0.9).toString();
            if (time11 < 60) {
              circle1.move({ x: 0, y: -8 });
              circle2.move({ x: 0, y: -8 });
              circle3.move({ x: 0, y: -8 });
              circle1.opacity(0.2, 0.01);
              circle2.opacity(0.2, 0.01);
              circle3.opacity(0.2, 0.01);
            } else if (time11 < 120) {
              circle1.respace({ x: -91, y: 8 });
              circle2.respace({ x: -91, y: 8 });
              circle3.respace({ x: -91, y: 8 });
              circle1.resize(200);
              circle2.resize(200);
              circle3.resize(200);
              circle1.opacity(0.2, 0.05);
              circle2.opacity(0.2, 0.05);
              circle3.opacity(0.2, 0.05);
            } else {
              opacity_page_1 = (
                parseFloat(opacity_page_1) * 0.95 +
                0.05
              ).toString();
            }
          } else {
            time10 = time10 + 1;
            time11 = 0;
            opacity_page_1 = (parseFloat(opacity_page_1) * 0.9).toString();
            opacity_page_0 = (
              parseFloat(opacity_page_0) * 0.8 +
              0.2
            ).toString();
            if (time10 < 240) {
              circle1.respace({ x: 0, y: -15.86 }, 0.08);
              circle2.respace({ x: -8, y: -2 }, 0.08);
              circle3.respace({ x: 8, y: -2 }, 0.08);
              circle1.resize(21.04, 0.08);
              circle2.resize(21.04, 0.08);
              circle3.resize(21.04, 0.08);
              circle1.opacity(1, 0.1);
              circle2.opacity(1, 0.1);
              circle3.opacity(1, 0.1);
            }
          }
          pElement.forEach((element) => {
            element.style.opacity = opacity_page_0;
          });
          line.forEach((element) => {
            element.style.opacity = opacity_page_0;
          });
          // console.log(pageRef.current!.style.opacity)
          pageRef.current!.style.opacity = opacity_page_1;
        });
        requestAnimationFrame(move);
      };
      move();
    }
  }, []);
  return (
    <div className={styles.body} style={{ position: "relative" }}>
      <div className={styles.page}>
        <div className={styles.banner1}>
          <p className={`${styles.p_1} pe`} style={{ opacity: "1" }}>
            产品技术
          </p>
          <div
            className={`${styles.line} line`}
            style={{ top: "-2vw", width: "7vw" }}
          ></div>
          <p
            className={`${styles.p_2} pe`}
            style={{ top: "3vw", opacity: "1" }}
          >
            本公司专注于EQ增强AGI数智人驱动平台的研发与应用推广,通过AGI数智人的EQ增强,形成包括观察感知、分享交互、丰富提升三大模块技术的人类情绪价值“生产线”,并自主研发了一套低代码、高模块化的热更新烘焙引擎,提供一整套交互代码的开发解决方案,致力于成为优质的AIaaS供应商。
          </p>
        </div>
        <div className={styles.banner2}>
          <div style={{ position: "relative" }}>
            <div
              className={styles.circle1}
              ref={circle1Ref}
              style={{
                position: "absolute",
                left: "0vw",
                top: "-15.86vw",
                width: "21.04vw",
                height: "21.04vw",
              }}
            >
              <p className={`${styles.p_3} pe`} style={{ opacity: "1" }}>
                观察感知模型
              </p>
            </div>
            <div
              className={styles.circle2}
              ref={circle2Ref}
              style={{
                position: "absolute",
                left: "-8vw",
                top: "-2vw",
                width: "21.04vw",
                height: "21.04vw",
              }}
            >
              <p className={`${styles.p_3} pe`} style={{ opacity: "1" }}>
                分享交互模型
              </p>
            </div>
            <div
              className={styles.circle3}
              ref={circle3Ref}
              style={{
                position: "absolute",
                left: "8vw",
                top: "-2vw",
                width: "21.04vw",
                height: "21.04vw",
              }}
            >
              <p className={`${styles.p_3} pe`} style={{ opacity: "1" }}>
                丰富提升模型
              </p>
            </div>
          </div>
        </div>
        <div className={styles.banner3}>
          <div className={`${styles.line} line`}></div>
          <p className={`${styles.p_4} pe`} style={{ opacity: "1" }}>
            情绪数据库构建
            <br />
            情绪识别模型算法
            <br />
            关注情绪的细颗粒度用户画像
            <br />
          </p>
          <div className={`${styles.line} line`} style={{ top: "3vw" }}></div>
          <p
            className={`${styles.p_4} pe`}
            style={{ top: "3vw", opacity: "1" }}
          >
            多模态情绪识别与交互引擎
            <br />
            ⽤户参与式的AI智能烘焙引擎
            <br />
            定制化科技树引擎
            <br />
            AI动作捕捉+⽣成引擎 <br />
          </p>
        </div>
      </div>
      <div
        className={styles.page}
        style={{ position: "absolute", opacity: "0", left: "0" }}
        ref={pageRef}
      >
        <div className={styles.banner}>
          <p className={styles.p_1} style={{ top: "1vw" }}>
            产品介绍
          </p>
          <div
            className={styles.line}
            style={{ top: "-1vw", left: "0vw", width: "6.3vw" }}
          ></div>
          <p className={styles.p_5}>
            华数集团："小数“——数智家庭生活管家AI机器人
          </p>
          <p className={styles.p_4} style={{ top: "-2vw", width: "101%" }}>
            小数是一款智慧家庭管家AI全息仓。采用全息仓结构+AI虚拟人的设计,服务老年人居家养老场景。作为新一代智慧家庭的终端入口,可以为老年人提供接入数字生活的捷径,为消除老年人数字鸿沟问题提供一种可能性,本产品作为典型案例已经被《人民日报》要闻头条报道。
          </p>
          <div className={styles.news}>
            <img src="./images/xiaoshu1.webp" alt="" />
            <img src="./images/xiaoshu2.webp" alt="" />
          </div>
        </div>
      </div>
      <div className={styles.page}>
        <div
          className={styles.banner}
          style={{
            height: "30vw",
          }}
        >
          <p className={styles.p_1} style={{ top: "1vw" }}>
            产品介绍
          </p>
          <div
            className={styles.line}
            style={{ top: "-1vw", left: "0vw", width: "6.3vw" }}
          ></div>
          <div className={styles.box}>
            <div className={styles.Player}>
              <img src="./images/model.png" alt="" />

              <video controls>
                <source src="./videos/car_av1.webm" type="video/mp4; codecs=av01" />
                <source src="./videos/car.mp4" type="video/mp4" />
                <p>您的浏览器不支持video元素。</p>
              </video>
            </div>
            <div className={styles.box_p}>
              <p className={styles.p_5}>汽车内外饰定制工具</p>
              <p className={styles.p_4}>
                专属于个人性格爱好的汽车内外饰改装的人工智能工具
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.row} style={{ left: "3vw", top: "-0.4vw" }}>
            <img src="./images/白色长.png" alt="" />
            <div className={styles.columline}></div>
            <div className={styles.info}>
              <p className={styles.p_6}>
                地址：杭州市余杭区浙江大学校友企业总部经济园
                <br />
                微信公众号：像素跃迁
                <br />
                邮箱：support@paeleap.com
              </p>
            </div>
          </div>
          <div className={styles.row} style={{ left: "2vw", top: "-0.8vw" }}>
            <p className={styles.p_7}>
              Copyright©2023 版权所有 杭州像素跃迁科技有限公司 备案号：
              <a href="https://beian.miit.gov.cn">浙ICP备2022036123号-1</a>
            </p>
          </div>
          <div
            className={styles.row}
            style={{ left: "-0.6vw", top: "-1.2vw", height: "3vw" }}
          >
            <img style={{ height: "1vw" }} src="./images/gongan.webp" alt="" />
            <p className={styles.p_7} style={{ left: "0.5vw", margin: "0" }}>
              <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016879">
                浙公网安备33011002016879号
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductPage = (props: {}) => {
  return (
    <div>
      {/* <Nav page={11} /> */}
      <Body />
    </div>
  );
};
